import React from "react";
import { ReactComponent as IconInstagram } from "./assets/icons/instagram.svg";
import { ReactComponent as IconLinkedIn } from "./assets/icons/linkedin.svg";
import logo from "./assets/icons/logo_l2r.png";
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href=".">
              {" "}
              <img src={logo} alt="Logo" className="logo-image" />{" "}
            </a>
          </div>
          <div className="social">
            <a
              href="https://www.instagram.com/nusantara_codeworks/"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconInstagram className="icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/nusantara-codeworks"
              title="Linkedin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconLinkedIn className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Get ready for the change.</h1>
            <p>Website coming soon. Please check back to know more. Shoot us an email if you're curious.</p>
          </div>
          <a href="mailto:hello@nu-code.com">
            <div className="cta">Send us an email</div>
          </a>
        </div>
      </div>
    );
  };
}

export default App;
